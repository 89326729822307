<template>
  <div class="wrap">
    <el-row :gutter="20">
      <el-col :span="10">
        <h5 class="text">订单金额汇总</h5>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-form
            ref="form"
            inline
            label-position="right"
            class="demo-dynamic"
          >
            <el-form-item
              v-for="(lists, index) in allData.huizong1.list"
              :key="index"
              :label="lists.key"
            >
              <el-input
                :value="lists.value"
                disabled
                style="width: 120px"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-row>
        <h5 class="text">结算汇总</h5>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-form inline>
            <el-form-item
              v-for="(lists, index) in allData.huizong2.list"
              :key="index"
            >
              <span
                v-if="lists.sign != '$'"
                :class="lists.sign == '-' ? 'lu' : 'red'"
              >
                {{ lists.sign }}
              </span>
              <span v-else>=</span>
              <el-form-item :label="lists.key">
                <el-input
                  :value="lists.value"
                  disabled
                  style="width: 120px"
                ></el-input>
              </el-form-item>
            </el-form-item>
          </el-form>
        </el-row>
      </el-col>
      <el-col :span="12">
        <el-table stripe :data="allData.mx_receiv" height="500">
          <el-table-column
            label="收款方式"
            align="center"
            prop="name"
          ></el-table-column>
          <el-table-column
            label="金额"
            align="center"
            prop="money"
          ></el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <el-row>
      <el-col :span="15">
        <el-form inline>
          <el-form-item label="应交账：">
            <el-input
              :value="allData.res_account.account_payable"
              disabled
              style="width: 120px"
            ></el-input>
          </el-form-item>
          <el-form-item label="已入账：">
            <el-input
              :value="allData.res_account.account_paid"
              disabled
              style="width: 120px"
            ></el-input>
          </el-form-item>
          <el-form-item label="待入账：">
            <el-input
              :value="allData.res_account.account_unpaid"
              disabled
              style="width: 120px"
            ></el-input>
          </el-form-item>

          <h5>入账记录：</h5>
          <el-table stripe :data="accountData">
            <el-table-column label="入账金额" align="center" prop="money">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.money"
                  placeholder="请输入入账金额"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="入账账户" align="center" prop="">
              <template slot-scope="scope">
                <el-select v-model="scope.row.id" placeholder="请选择">
                  <el-option
                    v-for="item in paydata"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="备注" align="center" prop="money">
              <template slot-scope="scope">
                <el-input
                  v-if="caozuo != 2"
                  v-model="scope.row.remark"
                  placeholder="请输入备注"
                ></el-input>
                <span v-else>{{ scope.row.remark }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="时间"
              align="center"
              prop="add_time"
            ></el-table-column>
            <el-table-column
              v-if="caozuo != 2"
              label="操作"
              align="center"
              prop="money"
            >
              <template slot-scope="scope">
                <el-button type="text" @click="handlerAdd(scope.$index)">
                  添 加
                </el-button>
                <el-button type="text" @click="handlerRemove(scope.$index)">
                  删 除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>
<script>
  import { dateForm } from '@/utils/Time'
  import { postAction } from '@/api/Employee'

  export default {
    name: 'Jiaozhang',
    components: {},
    props: {
      allData: {
        type: Object,
        default: () => {},
      },
      accountData: {
        type: Array,
        default: () => [],
      },
      caozuo: {
        type: Number,
        default: () => {
          return 1
        },
      },
    },
    data() {
      return {
        url: {
          zhanghu: '/baseAdmin/common/account',
          // zhanghu: '/saleAdmin/duizhang/receiv-type',
        },
        paydata: [],
        // list: [
        //   {
        //     id: '',
        //     money: '',
        //     add_time: dateForm(new Date()),
        //   },
        // ],
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      console.log('复数句', this.$parent.data, this.allData)
      this.$parent
      // 账户下拉
      postAction(this.url.zhanghu, {}).then((res) => {
        this.paydata = res.data
      })
    },
    methods: {
      handlerAdd(index) {
        this.$emit('addAccountList', {
          id: '',
          money: '',
          edit: true,
          add_time: dateForm(new Date()),
        })
      },
      handlerRemove(index) {
        this.$emit('deleteAccount', index)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .lu {
    color: #67c23a;
    font-size: 24px;
  }
  .red {
    color: red;
    font-size: 24px;
  }
</style>
